import cookie from 'js-cookie'

document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelector('.header_cart_count')
    if (el) {
        let quantity = parseInt(cookie.get('cart_item_num'))
        quantity = !isNaN(quantity) ? quantity : 0;
        el.innerText = quantity
    }
})
